import React from "react";
import styles from "./Camera.module.css";

const Camera = ({ videoRef, stopCamera, setIsCameraVisible }) => {
  const stopCameraHandler = () => {
    setIsCameraVisible(false);
    stopCamera();
  };

  return (
    <div className={styles.cameraContainer}>
      <video className={styles.video} ref={videoRef} />
      {/* <button className={styles.closeCamera} onClick={stopCameraHandler}>
        <span class="material-symbols-rounded">no_photography</span>
      </button> */}
    </div>
  );
};

export default Camera;
