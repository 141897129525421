import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import useCamera from "../../Utils/Camera/useCamera";
import Camera from "../../UI/Camera/Camera";

const Home = () => {
  const { videoRef, captureAndSendImage, result } = useCamera();
  const [isCameraVisible, setIsCameraVisible] = useState(false);

  const [flashActive, setFlashActive] = useState(false);

  const handleFlashClick = () => {
    setFlashActive(true);
    setTimeout(() => setFlashActive(false), 500);
  };

  useEffect(() => {
    setIsCameraVisible(true);
  }, []);

  const getBackgroundColor = () => {
    switch (result) {
      case 0:
        return "white";
      case 1:
        return "red";
      case 2:
        return "blue";
      case 3:
        return "green";
      case 4:
        return "gold";
      case 5:
        return "black";
      default:
        return "white"; // Default background color
    }
  };

  const getFontColor = () => {
    return result === 0 ? "black" : "white";
  };

  const divStyle = {
    backgroundColor: getBackgroundColor(),
    color: getFontColor(),
  };

  return (
    <div className={styles.Home}>
      <div onClick={captureAndSendImage} className={styles.camContainer}>
        <div
          className={`${styles.flash} ${flashActive ? styles.flashActive : ""}`}
          onClick={handleFlashClick}
        ></div>

        {isCameraVisible && (
          <Camera videoRef={videoRef} setIsCameraVisible={setIsCameraVisible} />
        )}
      </div>
      <div style={divStyle} className={styles.responseContainer}>
        <h1 className={styles.responseValue}>{result ? result : "0"}</h1>
      </div>
    </div>
  );
};

export default Home;
