import { useEffect, useRef, useState } from "react";
import { useUnmount } from "react-use";
import axios from "axios";

const useCamera = () => {
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [result, setResult] = useState();

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        };
        streamRef.current = stream;
      }
    } catch (error) {
      console.error("Error starting camera:", error);
    }
  };

  useEffect(() => {
    startCamera();
  }, []);

  const stopCamera = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      streamRef.current = null;
    }
  };
  const captureAndSendImage = async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      // Convert the image on the canvas to binary data
      canvas.toBlob(async (blob) => {
        if (blob) {
          // Define custom headers
          const customHeaders = {
            "Content-Type": "multipart/form-data", // Set the content type to application/octet-stream
          };

          const formData = new FormData();
          formData.append("image", blob, "image.jpg"); // 'image' is the field name

          // Send the image to the server with custom headers and "application/octet-stream" content type
          try {
            const response = await axios.post(
              "https://ikiaapi.mohave.dev/uploadImage/",
              formData,
              {
                headers: customHeaders,
              }
            );
            console.log("response", response);
            setResult(response?.data);
          } catch (error) {
            console.error("Error sending the image to the server:", error);
          }
        }
      }, "image/jpeg");
    }
  };

  useUnmount(() => {
    stopCamera();
  });

  return { videoRef, stopCamera, captureAndSendImage, result };
};

export default useCamera;
